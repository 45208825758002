import React, { useMemo, useState } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useCustomerAccount } from "../../../../hooks/useCustomer"
import { useLanguage } from "../../../../hooks/useLanguage"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withAccountActivateForm = Component => ({ name = `AccountActivateForm` }) => {
  const [showPassword, setShowPassword] = useState(false)
  const {
    config: {
      settings: { params, routes },
    },
  } = useApp()
  const { activateCustomer, data, setData, loading, errors, success } = useCustomerAccount()
  const { getUrlParameter } = useRoutes()
  const locales = {
    ...useLanguage(`account`),
    ...useLanguage(`form`),
  }

  const { customerId, resetToken, initError } = useMemo(() => {
    const resetParams = getUrlParameter(params?.customer)?.split(`/`) || []

    const customerId = resetParams?.[0] || null
    const resetToken = resetParams?.[1] || null

    const initError = !customerId || !resetToken ? locales?.resetInvalid : null

    return { customerId, resetToken, initError }
  }, [])

  const handleToggle = () => {
    setShowPassword(prevState => !prevState)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    await activateCustomer(customerId, resetToken, data?.password, true)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const invalid = !data?.password || !data?.passwordConfirm || data?.password !== data?.passwordConfirm

  Component.displayName = name
  return (
    <Component
      data={data}
      errors={errors}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleToggle={handleToggle}
      initError={initError}
      invalid={invalid}
      loading={loading}
      locales={locales}
      routes={routes}
      showPassword={showPassword}
      success={success}
    />
  )
}
